export const environment = {
	production: false,
	envName: 'DEV',


	apdmServiceUrl: 'https://api-dev.is.ford.com/apdmcore/v4.0/',
	apdmWebDavUrl: 'https://api-dev.is.ford.com/apdm/v4.0/',
	apdmServiceDownloadUrl: 'https://api-dev.is.ford.com/folderdownload/v4.0/',

	//ADFS
	adfsUrl: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize',
	clientID: 'urn:apdatamanager:clientid:web_website001:dev',
	resource: 'urn:apdatamanager:resource:web_website001:dev',
	redirectUrl: getRedirectUrl(),

	folderDownloadMaxLimit: 104857600.0 // 100 GB
};

function getRedirectUrl(): string {
	return window.location.origin + '/';
}
